define("ember-svg-jar/inlined/shield-task-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" width=\"64\" height=\"64\" rx=\"32\" fill=\"#F2F7FF\"/><path d=\"M41.113 27.154a1.25 1.25 0 00-1.726-1.808L29.27 35.003l-3.636-3.637a1.25 1.25 0 00-1.768 1.768l4.5 4.5c.48.48 1.256.49 1.747.02l11-10.5zm-7.748-14.806a1.25 1.25 0 00-1.73 0c-2.759 2.643-6.685 4.077-10.037 4.84a35.654 35.654 0 01-4.203.69 29.02 29.02 0 01-1.653.122h-.022a1.25 1.25 0 00-1.22 1.25v9.5c0 4.837 1.243 9.658 4.098 13.775 2.861 4.126 7.29 7.469 13.529 9.418.243.076.503.076.746 0C45.44 48.016 50.5 38.497 50.5 29v-9.75A1.25 1.25 0 0049.28 18h-.022l-.08-.003a29.01 29.01 0 01-1.574-.12 35.655 35.655 0 01-4.202-.69c-3.353-.762-7.279-2.196-10.037-4.84zM17 28.75v-8.325a38.155 38.155 0 005.152-.8c3.218-.731 7.21-2.102 10.348-4.702 3.137 2.6 7.13 3.97 10.348 4.702a38.154 38.154 0 005.152.8V29c0 8.41-4.344 16.799-15.5 20.438-5.56-1.814-9.39-4.793-11.848-8.338C18.132 37.467 17 33.163 17 28.75z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "65",
      "height": "64",
      "viewBox": "0 0 65 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});
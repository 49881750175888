define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember/component", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat
      this.containerClassNamesString
      ' '
      this.attachmentClass
      ' '
      this.containerClass
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "JslKtcgF",
    "block": "[[[11,0],[16,0,[28,[37,1],[[30,0,[\"containerClassNamesString\"]],\" \",[30,0,[\"attachmentClass\"]],\" \",[30,0,[\"containerClass\"]]],null]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"div\",\"concat\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/in-place-dialog.hbs",
    "isStrictMode": false
  });
  let InPlaceDialog = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = class InPlaceDialog extends _component.default {
    get containerClassNamesString() {
      var _this$containerClassN, _this$containerClassN2;
      const addonClassNamesString = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place'].join(' ');
      const containerClassNamesString = ((_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 ? void 0 : _this$containerClassN.join) && ((_this$containerClassN2 = this.containerClassNames) === null || _this$containerClassN2 === void 0 ? void 0 : _this$containerClassN2.join(' ')) || this.containerClassNames || '';
      return "".concat(addonClassNamesString, " ").concat(containerClassNamesString);
    }
  }) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InPlaceDialog);
});
define("ember-svg-jar/inlined/shield-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" width=\"64\" height=\"64\" rx=\"32\" fill=\"#F2F7FF\"/><path d=\"M31.635 12.348a1.25 1.25 0 011.73 0c2.758 2.643 6.684 4.077 10.037 4.84 1.659.376 3.139.58 4.202.69a29.01 29.01 0 001.654.122H49.28a1.25 1.25 0 011.221 1.25V29c0 9.497-5.06 19.016-17.627 22.943a1.25 1.25 0 01-.746 0c-6.238-1.95-10.668-5.292-13.53-9.418-2.854-4.117-4.097-8.938-4.097-13.775v-9.5A1.25 1.25 0 0115.72 18h.022l.08-.003a29.02 29.02 0 001.574-.12 35.654 35.654 0 004.202-.69c3.352-.762 7.278-2.196 10.037-4.84zM17 20.425v8.325c0 4.413 1.132 8.717 3.652 12.35 2.459 3.545 6.288 6.524 11.848 8.338C43.656 45.798 48 37.41 48 29v-8.575a38.154 38.154 0 01-5.152-.8c-3.219-.731-7.21-2.102-10.348-4.702-3.138 2.6-7.13 3.97-10.348 4.702a38.155 38.155 0 01-5.152.8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "65",
      "height": "64",
      "viewBox": "0 0 65 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});
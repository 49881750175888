define("ember-modal-dialog/components/liquid-dialog", ["exports", "@ember/component", "ember-modal-dialog/components/basic-dialog", "@ember/template-factory"], function (_exports, _component, _basicDialog, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isOverlaySibling}}
    <LiquidWormhole
      @stack={{this.stack}}
      @value={{this.value}}
      @class={{concat
        'liquid-dialog-container '
        this.wrapperClassNamesString
        ' '
        this.wrapperClass
      }}
    >
      <div class='{{this.wrapperClassNamesString}} {{this.wrapperClass}}'>
        {{#if this.hasOverlay}}
          <EmberModalDialog::Overlay
            class={{this.overlayClassNamesString}}
            @onClickOverlay={{this.onClickOverlay}}
          />
        {{/if}}
        <div class={{this.containerClassNamesString}} ...attributes>
          {{yield}}
        </div>
      </div>
    </LiquidWormhole>
  {{else}}
    <LiquidWormhole
      @stack={{this.stack}}
      @value={{this.value}}
      @class={{concat
        'liquid-dialog-container '
        this.wrapperClassNamesString
        ' '
        this.wrapperClass
      }}
    >
      {{#if this.hasOverlay}}
        <EmberModalDialog::Overlay
          class={{this.overlayClassNamesString}}
          @onClickOverlay={{action (ignore-children this.onClickOverlay)}}
        >
          <div class={{this.containerClassNamesString}} ...attributes>
            {{yield}}
          </div>
        </EmberModalDialog::Overlay>
      {{else}}
        <div class={{this.containerClassNamesString}} ...attributes>
          {{yield}}
        </div>
      {{/if}}
    </LiquidWormhole>
  {{/if}}
  */
  {
    "id": "kAzRy8Ng",
    "block": "[[[41,[30,0,[\"isOverlaySibling\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@stack\",\"@value\",\"@class\"],[[30,0,[\"stack\"]],[30,0,[\"value\"]],[28,[37,2],[\"liquid-dialog-container \",[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"        \"],[8,[39,4],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[30,0,[\"onClickOverlay\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@stack\",\"@value\",\"@class\"],[[30,0,[\"stack\"]],[30,0,[\"value\"]],[28,[37,2],[\"liquid-dialog-container \",[30,0,[\"wrapperClassNamesString\"]],\" \",[30,0,[\"wrapperClass\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasOverlay\"]],[[[1,\"      \"],[8,[39,4],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[28,[37,6],[[30,0],[28,[37,7],[[30,0,[\"onClickOverlay\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n          \"],[18,2,null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,0],[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"liquid-wormhole\",\"concat\",\"div\",\"ember-modal-dialog/overlay\",\"yield\",\"action\",\"ignore-children\"]]",
    "moduleName": "ember-modal-dialog/components/liquid-dialog.hbs",
    "isStrictMode": false
  });
  class LiquidDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "variantWrapperClass", 'emd-animatable');
    }
    init() {
      var _this$containerClassN;
      super.init(...arguments);
      (_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 || _this$containerClassN.push('liquid-dialog');
    }
  }
  _exports.default = LiquidDialog;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LiquidDialog);
});
define("ember-modal-dialog/utils/ember-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.dasherize = dasherize;
  _exports.decamelize = decamelize;
  const STRING_CAPITALIZE_REGEXP = /(^|\/)([a-z\u00C0-\u024F])/g;
  const STRING_DASHERIZE_REGEXP = /[ _]/g;
  const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
  function capitalize(str) {
    return str.replace(STRING_CAPITALIZE_REGEXP, match => match.toUpperCase());
  }
  function dasherize(str) {
    return decamelize(str).replace(STRING_DASHERIZE_REGEXP, '-');
  }
  function decamelize(str) {
    return str.replace(STRING_DECAMELIZE_REGEXP, '$1_$2').toLowerCase();
  }
});
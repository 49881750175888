define("ember-modal-dialog/components/ember-modal-dialog/overlay", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "ember-modal-dialog/utils/config-utils", "@ember/template-factory"], function (_exports, _component, _object, _component2, _configUtils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="emd-debug"
    tabindex='-1'
    data-emd-overlay
    {{did-insert this.didInsert}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "eN+yPa7c",
    "block": "[[[11,0],[24,0,\"emd-debug\"],[24,\"tabindex\",\"-1\"],[24,\"data-emd-overlay\",\"\"],[17,1],[4,[38,1],[[30,0,[\"didInsert\"]]],null],[4,[38,2],[[30,0,[\"willDestroyNode\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"div\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/ember-modal-dialog/overlay.hbs",
    "isStrictMode": false
  });
  let EmberModalDialogOverlayComponent = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (_class2 = class EmberModalDialogOverlayComponent extends _component.default {
    _onClickOverlay(event) {
      let {
        onClickOverlay
      } = this;
      if (onClickOverlay) {
        onClickOverlay(event);
      }
    }
    didInsert(element) {
      const registerClick = () => {
        element.addEventListener('click', this._onClickOverlay);
      };

      // setTimeout needed or else the click handler will catch the click that spawned this modal dialog
      setTimeout(registerClick, (0, _configUtils.clickHandlerDelay)(this));
      if (_configUtils.isIOS) {
        element.style.cursor = 'pointer';
      }
    }
    willDestroyNode(element) {
      element.removeEventListener('click', this._onClickOverlay);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "_onClickOverlay", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onClickOverlay"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "didInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "willDestroyNode"), _class2.prototype), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EmberModalDialogOverlayComponent);
});
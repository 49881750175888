define("ember-modal-dialog/components/modal-dialog", ["exports", "@ember/component", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/utils", "@ember/debug", "@embroider/util", "ember-modal-dialog/utils/ember-string", "@ember/template-factory", "@embroider/macros/es-compat2"], function (_exports, _component, _component2, _object, _service, _computed, _utils, _debug, _util, _emberString, _templateFactory, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.whichModalDialogComponent
    @wrapperClass={{this.wrapperClass}}
    @wrapperClassNames={{this.wrapperClassNames}}
    @overlayClass={{this.overlayClass}}
    @overlayClassNames={{this.overlayClassNames}}
    @containerClass={{this.containerClass}}
    @containerClassNames={{this.containerClassNames}}
    @hasOverlay={{this.hasOverlay}}
    @translucentOverlay={{this.translucentOverlay}}
    @clickOutsideToClose={{this.clickOutsideToClose}}
    @destinationElementId={{this.destinationElementId}}
    @overlayPosition={{this.overlayPosition}}
    @tetherTarget={{this.tetherTarget}}
    @legacyTarget={{this.target}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @targetOffset={{this.targetOffset}}
    @offset={{this.offset}}
    @tetherClassPrefix={{this.tetherClassPrefix}}
    @constraints={{this.constraints}}
    @attachmentClass={{this.attachmentClass}}
    @stack={{this.stack}}
    @value={{this.value}}
    @onClickOverlay={{this.onClickOverlayAction}}
    @onClose={{this.onCloseAction}}
    ...attributes
  >
    {{yield}}
  </this.whichModalDialogComponent>
  */
  {
    "id": "Xz+tCQMj",
    "block": "[[[8,[30,0,[\"whichModalDialogComponent\"]],[[17,1]],[[\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@destinationElementId\",\"@overlayPosition\",\"@tetherTarget\",\"@legacyTarget\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"@attachmentClass\",\"@stack\",\"@value\",\"@onClickOverlay\",\"@onClose\"],[[30,0,[\"wrapperClass\"]],[30,0,[\"wrapperClassNames\"]],[30,0,[\"overlayClass\"]],[30,0,[\"overlayClassNames\"]],[30,0,[\"containerClass\"]],[30,0,[\"containerClassNames\"]],[30,0,[\"hasOverlay\"]],[30,0,[\"translucentOverlay\"]],[30,0,[\"clickOutsideToClose\"]],[30,0,[\"destinationElementId\"]],[30,0,[\"overlayPosition\"]],[30,0,[\"tetherTarget\"]],[30,0,[\"target\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"targetOffset\"]],[30,0,[\"offset\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"constraints\"]],[30,0,[\"attachmentClass\"]],[30,0,[\"stack\"]],[30,0,[\"value\"]],[30,0,[\"onClickOverlayAction\"]],[30,0,[\"onCloseAction\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/modal-dialog.hbs",
    "isStrictMode": false
  });
  const VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];
  let ModalDialog = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _service.inject)('modal-dialog'), _dec3 = (0, _computed.readOnly)('modalService.hasLiquidWormhole'), _dec4 = (0, _computed.readOnly)('modalService.hasLiquidTether'), _dec5 = (0, _computed.oneWay)('elementId'), _dec6 = (0, _object.computed)('attachment'), _dec7 = (0, _object.computed)('renderInPlace', 'tetherTarget', 'animatable', 'hasLiquidWormhole', 'hasLiquidTether'), _dec(_class = (_class2 = class ModalDialog extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalService", _descriptor, this);
      _defineProperty(this, "animatable", null);
      _defineProperty(this, "clickOutsideToClose", false);
      _defineProperty(this, "destinationElementId", null);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "overlayPosition", 'parent');
      // `parent` or `sibling`
      _defineProperty(this, "renderInPlace", false);
      _defineProperty(this, "targetAttachment", 'middle center');
      _defineProperty(this, "tetherClassPrefix", null);
      _defineProperty(this, "tetherTarget", null);
      _defineProperty(this, "translucentOverlay", false);
      _defineProperty(this, "value", 0);
      // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether
      _initializerDefineProperty(this, "hasLiquidWormhole", _descriptor2, this);
      _initializerDefineProperty(this, "hasLiquidTether", _descriptor3, this);
      _initializerDefineProperty(this, "stack", _descriptor4, this);
    }
    // pass a `stack` string to set a "stack" to be passed to liquid-wormhole / liquid-tether

    get attachmentClass() {
      let attachment = this.attachment;
      if ((0, _utils.isEmpty)(attachment)) {
        return undefined;
      }
      return attachment.split(' ').map(attachmentPart => {
        return "emd-attachment-".concat((0, _emberString.dasherize)(attachmentPart));
      }).join(' ');
    }
    get whichModalDialogComponent() {
      let {
        animatable,
        hasLiquidTether,
        hasLiquidWormhole,
        tetherTarget
      } = this;
      let module = (0, _esCompat.default)(require("ember-modal-dialog/components/basic-dialog"));
      if (this.renderInPlace) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/in-place-dialog"));
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-tether-dialog"));
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/tether-dialog"));
      } else if (hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-dialog"));
      }
      return (0, _util.ensureSafeComponent)(module.default, this);
    }
    init() {
      super.init(...arguments);
      if (!this.destinationElementId) {
        (0, _object.set)(this, 'destinationElementId', this.modalService.destinationElementId);
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (false /* DEBUG */) {
        this.validateProps();
      }
    }
    validateProps() {
      let overlayPosition = this.overlayPosition;
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (false && (0, _debug.warn)("overlayPosition value '".concat(overlayPosition, "' is not valid (valid values [").concat(VALID_OVERLAY_POSITIONS.join(', '), "])"), false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    }
    ensureEmberTetherPresent() {
      if (!this.modalService.hasEmberTether) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    }
    onCloseAction() {
      const onClose = this.onClose;
      // we shouldn't warn if the callback is not provided at all
      if ((0, _utils.isNone)(onClose)) {
        return;
      }
      (false && !((0, _utils.typeOf)(onClose) === 'function') && (0, _debug.assert)('onClose handler must be a function', (0, _utils.typeOf)(onClose) === 'function'));
      onClose();
    }
    onClickOverlayAction(e) {
      e.preventDefault();
      const onClickOverlay = this.onClickOverlay;
      // we shouldn't warn if the callback is not provided at all
      if ((0, _utils.isNone)(onClickOverlay)) {
        this.onCloseAction();
        return;
      }
      (false && !((0, _utils.typeOf)(onClickOverlay) === 'function') && (0, _debug.assert)('onClickOverlay handler must be a function', (0, _utils.typeOf)(onClickOverlay) === 'function'));
      onClickOverlay();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "modalService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasLiquidWormhole", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "hasLiquidTether", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "stack", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "attachmentClass", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "attachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "whichModalDialogComponent", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "whichModalDialogComponent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCloseAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCloseAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onClickOverlayAction", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onClickOverlayAction"), _class2.prototype), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalDialog);
});